import React, { Component } from "react"
import { findDOMNode } from "react-dom"
import ReactPlayer from "react-player"
import screenfull from "screenfull"
import styled from "styled-components"
import { navigate } from "gatsby-link"
import { Link } from "gatsby"
import { NotificationManager } from "react-notifications"

import * as Pl from "../Styled/player"

import {
  MEDIA_TYPE_VIDEO,
  convertToSlug,
  stationVideoDetailsPageURL,
  stationVideosPageURL,
  artistVideosPageURL,
  stationDetailsURL,
  artistVideoDetailsPageURL,
  stationPodcastsPageURL,
  artistDetailsURL,
  artistPodcastsPageURL,
  COUNTRY_ALL,
  VIDEO_MODE_CLOSED,
  VIDEO_MODE_MAXIMIZED,
  VIDEO_MODE_MINIMIZED,
  DATA_TYPE_ARTIST,
  DATA_TYPE_ARTIST_PODCAST,
  DATA_TYPE_ARTIST_VIDEO,
  DATA_TYPE_STATION,
  DATA_TYPE_STATION_VIDEO,
  DATA_TYPE_STATION_PODCAST,
} from "../../constants/props"
import { COLOR_THEME_COLOR } from "../../constants/colors"
import Duration from "./Duration"
import { stationVideoDetailsShareURL } from "../../constants/share"
import BaseSlider from "./BaseSlider"
import VideoSlider from "./VideoSlider"

import "./style.css"
import { AppContext } from "../../contexts/AppContext"
import LikeAndShare from "../LikeAndShare"
import {
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
  MOBILE,
  DEVICE_LANDSCAPE,
  DEVICE_LARGE,
  DEVICE_MEDIUM,
  DEVICE_MOBILE,
  DEVICE_TAB,
} from "../../constants/breakpoints"

// border: 2px solid blue;
const FullContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  @media (max-width: ${MOBILE.maxWidth}px) {
    top: ${MOBILE.header.height +
  MOBILE.header.subMenuFont +
  MOBILE.header.bottomMargin}vh;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    top: 0px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    top: ${TAB.header.height +
  TAB.header.subMenuFont +
  TAB.header.bottomMargin}vh;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    top: ${MEDIUM.header.height +
  MEDIUM.header.subMenuFont +
  MEDIUM.header.bottomMargin}vh;
    margin-top: ${MEDIUM.player.topMargin}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    top: ${LARGE.header.height +
  LARGE.header.subMenuFont +
  LARGE.header.bottomMargin}vh;
    margin-top: ${LARGE.player.topMargin}px;
  }
  z-index: 10010;
`

const PlayerVolumeControlsImg = styled.img`
  /* border: 1px solid darkgreen; */
  /*width: ${props => props.width};*/
  opacity: ${props => (props.disabled ? "0.3" : "1.0")};
  cursor: pointer;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.layout.volumeButton}px;
  }
`

const PlayerWrapper = styled.div`
  /* border: 2px solid green; */
  display: block;
  background: black;
  margin: auto auto;
  position: fixed;
  overflow-y: hidden;
  /* background-image: url(${props => props.image}); */
  ${props =>
    !props.ismobile
      ? `&:hover .video-ctrl-showhide {
    transform: translateY(0);
  }`
      : ""}
`

const ItemTitle = styled(Link)`
  color: #fff;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
  &:focus {
    text-decoration: none;
    color: #fff;
  }
`
const LikeShare = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  flex: 1;
  position: relative;
  padding-left: 10px;
`

const getCurrentIndex = (list, id) => {
  const idx = list && list.length && list.findIndex(obj => obj.id === id)
  return idx
}

/*
This method returns if video should be played in maximized mode or not. This is similar to how it works in youtube where you see video on middle of screen and related videos around it.
If you run a miniplayer of youtube it minimizes player into bottom right of screen with other functionality of youtube becomes visible.
So when page is not a video page like podcast or countries, then player would float at bottom right of screen (if something was playing)
While when page is video page video becomes embedded into page itself with related contents around it.
*/

const isMaximizedView = (pathType, mode) => {
  if (pathType === "stationvideodetails" || pathType === "artistvideodetails")
    return true
  // video details page would show video in maximized view
  else if (pathType === "stationvideos" || pathType === "artistvideos") {
    // videos page would show videos differently based on mode value
    if (mode === VIDEO_MODE_MAXIMIZED) return true
    else return false
  } else {
    return false // all other pages would show videos as floating
  }
}

const retPlayerStyle = (mediaType, device, floating) => {
  let width = 0
  let height = 0
  let bottom = 0

  switch (device) {
    case DEVICE_MOBILE:
      width =
        (!floating
          ? MOBILE.box.videos.playing.width
          : MOBILE.box.videos.playing.floatingWidth) + "vw"
      height =
        (!floating
          ? MOBILE.box.videos.playing.height
          : MOBILE.box.videos.playing.floatingHeight) + "vw"
      bottom = MOBILE.player.controls.bottom.height + "vw"
      break
    case DEVICE_LANDSCAPE:
      width =
        (!floating
          ? LANDSCAPE.box.videos.playing.width
          : LANDSCAPE.box.videos.playing.floatingWidth) + "vw"
      height = !floating
        ? LANDSCAPE.box.videos.playing.height + "vh"
        : LANDSCAPE.box.videos.playing.floatingHeight + "vw"
      bottom = LANDSCAPE.player.controls.bottom.height + "px"
      break
    case DEVICE_TAB:
      width =
        (!floating
          ? TAB.box.videos.playing.width
          : TAB.box.videos.playing.floatingWidth) + "px"
      height =
        (!floating
          ? TAB.box.videos.playing.height
          : TAB.box.videos.playing.floatingHeight) + "px"
      bottom = TAB.player.controls.bottom.height + "px"
      break
    case DEVICE_MEDIUM:
      bottom = MEDIUM.player.controls.bottom.height + "px"
      width =
        (!floating
          ? MEDIUM.box.videos.playing.width
          : MEDIUM.box.videos.playing.floatingWidth) + "px"
      height =
        (!floating
          ? MEDIUM.box.videos.playing.height
          : MEDIUM.box.videos.playing.floatingHeight) + "px"
      break
    case DEVICE_LARGE:
      bottom = LARGE.player.controls.bottom.height + "px"
      width =
        (!floating
          ? LARGE.box.videos.playing.width
          : LARGE.box.videos.playing.floatingWidth) + "px"
      height =
        (!floating
          ? LARGE.box.videos.playing.height
          : LARGE.box.videos.playing.floatingHeight) + "px"
      break
  }

  if (floating) {
    return {
      display: mediaType === MEDIA_TYPE_VIDEO ? "block" : "none",
      position: "fixed",
      bottom,
      right: "0vw",
      width,
      height,
    }
  } else {
    return {
      display: mediaType === MEDIA_TYPE_VIDEO ? "block" : "none",
      position: "absolute",
      top: "0px",
      left: "50%",
      transform: "translate(-50%, 0)",
      width,
      height,
    }
  }
}

const PLAYER_INIT_STATE = {
  id: null,
  url: null,
  title: "",
  subtitle: "",
  playlist: null,
  data: null,
  currentPlayIndex: -1,
  pip: false,
  live: false,
  playing: false,
  controls: false,
  isBuffering: false,
  light: false,
  volume: 0.8,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  floating: true,
  showControls: true,
  playerStyle: {
    display: "none",
  },
  pathtype: "",
  videoMode: VIDEO_MODE_CLOSED,
  device: null,
}

class Player extends Component {
  state = PLAYER_INIT_STATE

  static contextType = AppContext

  componentDidUpdate(prevProps) {
    const { device, ctxPlayer, updatePlayer } = this.context
    if (this.state.id && !ctxPlayer.id) {
      // Player was playing and now it is closed, reset local player state as well
      this.setState(PLAYER_INIT_STATE)
    } else if (
      (ctxPlayer.uri === undefined && ctxPlayer.streamuri === undefined) ||
      this.state.id === ctxPlayer.id
    ) {
      if (
        this.props.pathtype &&
        prevProps.pathtype &&
        this.props.pathtype !== prevProps.pathtype
      ) {
        /*
        console.log(
          `[PLAYER] [MOUNT] path type changed, need to re-evaluate player behavior, pathtype [${this.props.pathtype}] prevpathtype [${prevProps.pathtype}] mode = [${ctxPlayer.videoMode}]`
        )
        */
        /*
          As previous path type is different then current path type, this surely means that URL is changed due to any user action. 
          We would now redraw player styles based on both content type (type of thing being played in player) as well as 
          pathtype. Current rule is we embed player on a video page when player plays a video and we are on video page while
          we make player float on a page on bottom/right when content type is video while we are on a non-video page (like countries, podcast etc)
        */
        this.setState({
          floating: !isMaximizedView(this.props.pathtype, ctxPlayer.videoMode),
          playerStyle: retPlayerStyle(
            ctxPlayer.type,
            device,
            !isMaximizedView(this.props.pathtype, ctxPlayer.videoMode)
          ),
          playlist: [], // empty playlist as it will be added when video details page calls updatePlayer with playlist
        })
      } else if (
        (!this.state.playlist || this.state.playlist.length === 0) &&
        ctxPlayer.playlist &&
        ctxPlayer.playlist.length
      ) {
        // change playlist if there was an empty playlist and now there are items
        //console.log(`[PLAYER] [MOUNT] playlist changed`)
        this.setState({
          playlist: ctxPlayer.playlist,
          currentPlayIndex: getCurrentIndex(ctxPlayer.playlist, this.state.id),
        })
      } else if (this.state.videoMode !== ctxPlayer.videoMode) {
        /*
        console.log(
          `[PLAYER] [MOUNT] video mode changed from ${this.state.videoMode} to ${ctxPlayer.videoMode}`
        )
        */

        this.setState({
          videoMode: ctxPlayer.videoMode,
          floating: !isMaximizedView(this.props.pathtype, ctxPlayer.videoMode),
          playerStyle: retPlayerStyle(
            ctxPlayer.type,
            device,
            !isMaximizedView(this.props.pathtype, ctxPlayer.videoMode)
          ),
        })
      } else if (this.state.device !== device) {
        /*
        console.log(
          `[PLAYER] [MOUNT] device changed from ${this.state.device} to ${device}`,
          this.player
        )
        */

        this.setState({
          playerStyle: retPlayerStyle(
            ctxPlayer.type,
            device,
            !isMaximizedView(this.props.pathtype, ctxPlayer.videoMode)
          ),
          device,
        })
      } else if (this.state.playing !== ctxPlayer.playing) {
        console.log(
          `[PLAYER] [MOUNT] playing changed this.state.playing ${this.state.playing} this.ctx.playing ${ctxPlayer.playing}`
        )

        if (this.state.internalAction) {
          // action is originated from inside of player hence change playing state on context player
          updatePlayer({
            ...ctxPlayer,
            playing: this.state.playing,
          })
          this.setState({
            internalAction: false,
          })
        } else {
          // action is originated from outside hence change it based on ctx player
          this.setState({
            playing: ctxPlayer.playing,
          })
        }
      }
      // return  null to prevent loading of new data as video url is not changed
      return null
    }

    this.load(device, ctxPlayer, this.props.pathtype)
  }

  load = (device, item, pathType) => {
    this.setState({
      id: item.id,
      type: item.type,
      live: item.uri ? false : true,
      url: item.uri || item.streamuri,
      title: item.title,
      subtitle: item.subtitle,
      light: isMaximizedView(pathType, item.videoMode) ? item.preview : false,
      playing: item.playing ? item.playing : true,
      isBuffering: item.isBuffering,
      playlist: item.playlist,
      floating: !isMaximizedView(pathType, item.videoMode),
      playerStyle: retPlayerStyle(
        item.type,
        device,
        !isMaximizedView(pathType, item.videoMode)
      ),
      data: item.data,
      currentPlayIndex: getCurrentIndex(item.playlist, item.id),
      pathtype: this.props.pathtype,
      station: item.station,
      artist: item.artist,
      videoMode: item.videoMode,
      device,
    })

    if (isMaximizedView(pathType, item.videoMode)) {
      setTimeout(() => {
        this.setState({ playing: true, light: false })
      }, 500)
    }
  }

  minimizeVideo = () => {
    const { selectedCountry, ctxPlayer, updatePlayer } = this.context

    if (
      this.props.pathtype === "stationvideos" ||
      this.props.pathtype === "artistvideos"
    ) {
      // minimizing on videos page would close player
      updatePlayer({
        ...ctxPlayer,
        videoMode: VIDEO_MODE_MINIMIZED,
      })
    } else {
      updatePlayer({
        ...ctxPlayer,
        videoMode: VIDEO_MODE_MINIMIZED,
      })

      if (this.state.station) {
        navigate(
          stationVideosPageURL(
            this.state.station.id,
            this.state.station.slug,
            selectedCountry ? selectedCountry.id : COUNTRY_ALL,
            selectedCountry ? convertToSlug(selectedCountry.title) : COUNTRY_ALL
          )
        )
      } else if (this.state.artist) {
        navigate(
          artistVideosPageURL(
            this.state.artist.id,
            this.state.artist.slug,
            selectedCountry ? selectedCountry.id : COUNTRY_ALL,
            selectedCountry ? convertToSlug(selectedCountry.title) : COUNTRY_ALL
          )
        )
      }
    }
  }

  snapToVideoPage = () => {
    if (this.state.station) {
      navigate(
        stationVideoDetailsPageURL(
          this.state.id,
          convertToSlug(this.state.title),
          this.state.station.id,
          this.state.station.slug
        )
      )
    } else if (this.state.artist) {
      navigate(
        artistVideoDetailsPageURL(
          this.state.id,
          convertToSlug(this.state.title),
          this.state.artist.id,
          this.state.artist.slug
        )
      )
    }
  }

  handlePlayPause = () => {
    if (this.state.url) {
      this.setState({
        playing: !this.state.playing,
        light: false,
        internalAction: true,
      })
    }
  }

  handleStop = () => {
    const { closePlayer } = this.context
    console.log("[PLAYER] handleStop")
    closePlayer()
  }

  handleToggleLight = () => {
    this.setState({ light: !this.state.light })
  }

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop })
  }

  handleVolumeChange = e => {
    //console.log("[PLAYER] Volume Change" + e.target.value)
    this.setState({ volume: parseFloat(e.target.value) })
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted })
  }

  handleSetPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) })
  }

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip })
  }

  handleStart = () => {
    console.log("[PLAYER] handleStart")
  }

  handleBuffer = () => {
    this.setState({ isBuffering: true })
  }

  handleBufferEnd = () => {
    this.setState({ isBuffering: false })
  }

  handleReady = () => {
    console.log("[PLAYER] handleReady")
  }

  handlePlay = () => {
    console.log("[PLAYER] handlePlay")
    if (!this.state.playing) {
      this.setState({ playing: true, internalAction: true })
    }
  }

  handleEnablePIP = () => {
    //console.log("[PLAYER] onEnablePIP")
    this.setState({ pip: true })
  }

  handleDisablePIP = () => {
    //console.log("[PLAYER] onDisablePIP")
    this.setState({ pip: false })
  }

  handlePause = () => {
    console.log("[PLAYER] handlePause() ", this.state.playing)
    if (this.state.playing) {
      // this means media is paused from player pause button most probably from lock screen
      this.setState({ playing: false, internalAction: true })
    }
  }

  handleSeekMouseDown = value => {
    this.setState({ seeking: true })
  }

  handleSeekChange = value => {
    let inputValue = value
    !this.state.live && this.setState({ played: parseFloat(inputValue) })
    !this.state.live && this.player.seekTo(parseFloat(inputValue))
  }

  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
  }

  handleProgress = progressInfo => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState({
        ...progressInfo,
      })
    }
  }

  handleError = error => {
    const { closePlayer } = this.context
    console.log("[PLAYER] error occurred while playing this media", error)
    // reset playing state to false as error occurred
    closePlayer()
    NotificationManager.error(
      "This stream is currently unavailable and we are working hard to restore it."
    )
  }

  handleEnded = () => {
    console.log("[PLAYER] onEnded")
    this.handleNextTrack()
  }

  handleDuration = duration => {
    console.log("[PLAYER] onDuration", duration)
    this.setState({
      duration,
    })
  }

  handleClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player))
  }

  hasPrevTrack = () => {
    let hasPrev = false
    if (this.state.playlist && this.state.playlist.length > 1) {
      hasPrev = this.state.currentPlayIndex > 0
    }
    return hasPrev
  }

  hasNextTrack = () => {
    let hasNext = false
    // User would cycle through all songs so if he clicks next on last song it would be take to first song
    // return true if there are more than one items in playlist
    if (this.state.playlist && this.state.playlist.length > 1) {
      return true
    }
    return hasNext
  }

  handlePrevTrack = () => {
    const { updatePlayer, navigateToLink } = this.context

    if (this.hasPrevTrack()) {
      // this.load(this.state.playlist[this.state.currentPlayIndex - 1])
      const {
        type,
        id,
        title,
        uri,
        streamuri,
        image,
        subtitle,
      } = this.state.playlist[this.state.currentPlayIndex - 1]
      navigateToLink({
        type: type,
        id: id,
        title: title,
        data: this.state.data,
      })

      updatePlayer({
        type: type || this.state.type,
        id,
        title,
        uri,
        streamuri,
        preview: image,
        subtitle,
        playlist: this.state.playlist,
        playerStyle: this.state.playerStyle,
      })
    } else {
      setTimeout(() => {
        this.setState({
          playing: false,
          played: 0,
          duration: 0,
          loaded: 0,
        })
      }, 1)
    }
  }

  handleNextTrack = () => {
    const { updatePlayer } = this.context
    if (this.state.playlist && this.state.playlist.length > 1) {
      const {
        id,
        title,
        uri,
        streamuri,
        image,
        subtitle,
      } = this.state.playlist[
        (this.state.currentPlayIndex + 1) % this.state.playlist.length
        ]
      updatePlayer({
        type: MEDIA_TYPE_VIDEO, // only video player shows this control
        id,
        title,
        uri,
        streamuri,
        preview: image,
        subtitle,
        playlist: this.state.playlist,
        playerStyle: this.state.playerStyle,
      })
    } else {
      this.setState({
        playing: 0,
        played: 0,
        duration: 0,
        loaded: 0,
      })
    }
  }

  ref = player => {
    this.player = player
  }

  // Styles for Pip
  DesktopPipVideoSmall = {
    display: "block",
    top: "-200%",
    right: "30px",
    width: "18.75vw",
    height: "10.83vw",
  }

  DesktopPipVideo = {
    display: "block",
    top: MOBILE.header.height + "vh",
    marginLeft: "3.94vw",
    width: "57.33vw",
    height: "32.24vw",
  }

  MobilePipVideo = {
    display: "block",
    top: "-140%",
    right: "4.17vw",
    width: "33.33vw",
    height: "18.75vw",
  }

  render() {
    const { device, selectedCountry, ctxPlayer } = this.context
    const {
      url,
      live,
      id,
      title,
      subtitle,
      playing,
      controls,
      light,
      station,
      artist,
      type,
      volume,
      muted,
      loop,
      played,
      duration,
      playbackRate,
      pip,
      floating,
      showControls,
      isBuffering,
      slug,
    } = this.state

    const titleSlug = slug ? slug : "all"
    const itemId = id ? id : "all"
    const countryNameSlug =
      selectedCountry && selectedCountry.name
        ? convertToSlug(selectedCountry.name)
        : "all"
    const countryID =
      selectedCountry && selectedCountry.id ? selectedCountry.id : "all"
    //console.log("isBuffering, ", isBuffering)
    return (
      <FullContainer>
        <PlayerWrapper
          ismobile={device === "mobile"}
          className="player-wrapper praveen"
          style={this.state.playerStyle}
          onDoubleClick={this.handleClickFullscreen}
          onClick={this.handlePlayPause}
          image={this.state.preview}
        >
          <ReactPlayer
            config={{
              youtube: {
                playerVars: {
                  playsinline: 0,
                  showinfo: 0,
                },
              },
            }}
            ref={this.ref}
            className="react-player"
            width="inherit"
            height="inherit"
            url={url}
            pip={pip}
            playsinline={true}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            playIcon={
              <img
                src={"/images/player/ItemHoverImage.png"}
                alt="Item Hover Image"
              />
            }
            onReady={this.handleReady}
            onStart={this.handleStart}
            onPlay={this.handlePlay}
            onEnablePIP={this.handleEnablePIP}
            onDisablePIP={this.handleDisablePIP}
            onPause={this.handlePause}
            onBuffer={this.handleBuffer}
            onBufferEnd={this.handleBufferEnd}
            onSeek={e => console.log("[PLAYER] onSeek", e)}
            onEnded={this.handleEnded}
            onError={this.handleError}
            onProgress={this.handleProgress}
            onDuration={this.handleDuration}
          />
          {floating ? (
            <div
              className="video-ctrl-close"
              style={{ top: "0.5rem", right: "0.5rem" }}
            >
              <Pl.PopupControlFloatingCloseImage
                src={"/images/player/videoCtrlMinClose.svg"}
                alt="Video Close"
                onDoubleClick={e => e.stopPropagation()}
                onClick={this.handleStop}
              />
            </div>
          ) : null}
          {!floating && showControls ? (
            <Pl.PopupControlNormalWrapper
              className="video-controls video-ctrl-showhide"
              onClick={e => e.stopPropagation()}
              onDoubleClick={e => e.stopPropagation()}
              playing={playing}
            >
              <VideoSlider
                min={0}
                max={1}
                step={0.00001}
                value={played}
                orientation={`horizontal`}
                reverse={false}
                tooltip={false}
                onChangeStart={this.handleSeekMouseDown}
                onChange={this.handleSeekChange}
                onChangeComplete={this.handleSeekMouseUp}
              />
              <Pl.PopupControlNormalSubWrapper height="inherit">
                <Pl.PopupControlNormalLeftControls>
                  <div onClick={this.handlePlayPause}>
                    {playing ? (
                      <Pl.PopupControlNormalImage
                        src={"/images/player/videoCtrlPause.svg"}
                        disabled={url === null ? true : false}
                        alt="Video Control Pause"
                      />
                    ) : (
                        <Pl.PopupControlNormalImage
                          src={"/images/player/videoCtrlPlay.svg"}
                          disabled={url === null ? true : false}
                          alt="Video Control Play"
                        />
                      )}
                  </div>
                  <div>
                    {this.hasNextTrack() ? (
                      <Pl.PopupControlNormalImage
                        src={"/images/player/videoCtrlNext.svg"}
                        alt="Video Control Next"
                        onClick={this.handleNextTrack}
                      />
                    ) : (
                        <Pl.PopupControlNormalImage
                          src={"/images/player/videoCtrlNext.svg"}
                          alt="Video Control Next"
                          disabled
                        />
                      )}
                  </div>
                  {url ? (
                    <Pl.PopupControlNormalTimeInfo>
                      {live ? (
                        <span role="img" aria-label="donut">
                          Live
                        </span>
                      ) : (
                          <Duration seconds={duration * played} />
                        )}
                      <span>{!live && " / "}</span>
                      <span>{!live && <Duration seconds={duration} />}</span>
                    </Pl.PopupControlNormalTimeInfo>
                  ) : (
                      ""
                    )}
                </Pl.PopupControlNormalLeftControls>

                <Pl.PopupControlNormalRightControls>
                  <Pl.PopupControlNormalVolumeWrapper>
                    <div
                      onClick={this.handleToggleMuted}
                      style={{ cursor: "pointer" }}
                    >
                      {muted ? (
                        <Pl.PopupControlNormalImage
                          src={"/images/player/videoCtrlVolumeOff.svg"}
                          alt="Volume Off"
                        />
                      ) : (
                          <Pl.PopupControlNormalImage
                            src={"/images/player/videoCtrlVolumeOn.svg"}
                            alt="Volume On"
                          />
                        )}
                    </div>
                    <Pl.PopupControlNormalVolumeSlider
                      className="video-vol-slider"
                      bgColor="#a2a2a2"
                      fgColor={COLOR_THEME_COLOR}
                      ismobile={device === "mobile"}
                      type="range"
                      min={0}
                      max={1}
                      step="any"
                      value={volume}
                      onChange={this.handleVolumeChange}
                    />
                  </Pl.PopupControlNormalVolumeWrapper>
                  <Pl.PopupControlNormalImage
                    src={"/images/player/videoCtrlMinimize.svg"}
                    alt="Video Control Minimize"
                    onClick={e => {
                      e.stopPropagation()
                      this.minimizeVideo(device === "mobile")
                    }}
                  />
                  <Pl.PopupControlNormalImage
                    src={"/images/player/videoCtrlFullScreen.svg"}
                    alt="Video Full Screen"
                    onClick={e => {
                      e.stopPropagation()
                      this.handleClickFullscreen()
                    }}
                  />
                </Pl.PopupControlNormalRightControls>
              </Pl.PopupControlNormalSubWrapper>
            </Pl.PopupControlNormalWrapper>
          ) : (
              showControls && (
                <Pl.PopupControlFloatingWrapper
                  playing={playing}
                  className="video-controls-min video-ctrl-showhide"
                >
                  <Pl.PopupControlFloatingLeftControls>
                    <div onClick={this.handlePlayPause}>
                      {playing ? (
                        <Pl.PopupControlFloatingImage
                          src={"/images/player/videoCtrlMinPause.svg"}
                          disabled={url === null ? true : false}
                        />
                      ) : (
                          <Pl.PopupControlFloatingImage
                            src={"/images/player/videoCtrlMinPlay.svg"}
                            playpause={true}
                            disabled={url === null ? true : false}
                          />
                        )}
                    </div>
                    <Pl.PopupControlFloatingTitle>
                      {title}
                    </Pl.PopupControlFloatingTitle>
                  </Pl.PopupControlFloatingLeftControls>
                  <Pl.PopupControlFloatingRightControls>
                    <Pl.PopupControlFloatingImage
                      src={"/images/player/videoCtrlMaximize.svg"}
                      alt="Video Maximize"
                      onClick={e => {
                        e.stopPropagation()
                        this.snapToVideoPage()
                      }}
                    />
                    <LikeShare>
                      <LikeAndShare
                        shareURL={
                          selectedCountry && selectedCountry.id
                            ? stationVideoDetailsShareURL(
                              id,
                              convertToSlug(title),
                              station ? station.id : null,
                              station ? station.slug : null,
                              this.state.preview
                            )
                            : null
                        }
                        shareBoxArrange={false}
                        popupShareBox={true}
                        id={id}
                        likeType={DATA_TYPE_STATION_VIDEO}
                        shareID={"selectedVideo_" + id}
                      />
                    </LikeShare>
                  </Pl.PopupControlFloatingRightControls>
                </Pl.PopupControlFloatingWrapper>
              )
            )}
        </PlayerWrapper>
        <Pl.BottomControlsWrapper
          className="base-outer"
          maximized={isMaximizedView(this.props.pathtype, this.state.videoMode)}
        >
          <BaseSlider
            min={0}
            max={1}
            step={0.00001}
            value={played}
            orientation={`horizontal`}
            reverse={false}
            tooltip={false}
            onChangeStart={this.handleSeekMouseDown}
            onChange={this.handleSeekChange}
            onChangeComplete={this.handleSeekMouseUp}
          />
          <Pl.BottomControlsSubWrapper>
            <Pl.BottomControlsLeftSection>
              <Pl.BottomControlsInfoWrapper>
                {ctxPlayer.id && (
                  <Pl.BottomControlsTitle>
                    <span>
                      {ctxPlayer.dataType === DATA_TYPE_STATION ? (
                        <ItemTitle to={stationDetailsURL(titleSlug)}>
                          {title}
                        </ItemTitle>
                      ) : ctxPlayer.dataType === DATA_TYPE_STATION_PODCAST ? (
                        <ItemTitle
                          to={stationPodcastsPageURL(
                            station ? station.id : null,
                            station ? station.slug : null,
                            countryID,
                            countryNameSlug
                          )}
                        >
                          {title}
                        </ItemTitle>
                      ) : ctxPlayer.dataType === DATA_TYPE_STATION_VIDEO ? (
                        <ItemTitle
                          to={stationVideoDetailsPageURL(
                            itemId,
                            titleSlug,
                            station ? station.id : null,
                            station ? station.slug : null
                          )}
                        >
                          {title}
                        </ItemTitle>
                      ) : ctxPlayer.dataType === DATA_TYPE_ARTIST ? (
                        <ItemTitle
                          to={artistDetailsURL(
                            itemId,
                            titleSlug,
                            countryID,
                            countryNameSlug
                          )}
                        >
                          {title}
                        </ItemTitle>
                      ) : ctxPlayer.dataType === DATA_TYPE_ARTIST_PODCAST ? (
                        <ItemTitle
                          to={artistPodcastsPageURL(
                            artist ? artist.id : null,
                            artist ? artist.slug : null,
                            countryID,
                            countryNameSlug
                          )}
                        >
                          {title}
                        </ItemTitle>
                      ) : ctxPlayer.dataType === DATA_TYPE_ARTIST_VIDEO ? (
                        <ItemTitle
                          to={artistVideoDetailsPageURL(
                            itemId,
                            titleSlug,
                            artist ? artist.id : null,
                            artist ? artist.slug : null
                          )}
                        >
                          {title}
                        </ItemTitle>
                      ) : (
                                    <ItemTitle>{title}</ItemTitle>
                                  )}
                    </span>
                  </Pl.BottomControlsTitle>
                )}
                <Pl.BottomControlsSubtitle>
                  {subtitle}
                </Pl.BottomControlsSubtitle>
              </Pl.BottomControlsInfoWrapper>
              {url ? (
                <Pl.BottomControlsTimeInfo>
                  {live ? (
                    <span role="img" aria-label="donut">
                      Live
                    </span>
                  ) : (
                      <Duration seconds={duration * played} />
                    )}
                  <span>{!live && " / "}</span>
                  <span>{!live && <Duration seconds={duration} />}</span>
                </Pl.BottomControlsTimeInfo>
              ) : (
                  ""
                )}
            </Pl.BottomControlsLeftSection>
            <Pl.BottomControlsCenterSection>
              {this.hasPrevTrack() ? (
                <Pl.BottomControlsNextPrevImage
                  src={"/images/player/previous.png"}
                  onClick={this.handlePrevTrack}
                  alt="Previous Track"
                />
              ) : (
                  <Pl.BottomControlsNextPrevImage
                    src={"/images/player/previous.png"}
                    alt="Previous Track"
                    disabled
                  />
                )}
              {playing ? (
                !isBuffering ? (
                  <Pl.BottomControlsPlayImage
                    src={"/images/player/pause.png"}
                    alt="Pause"
                    playpause={true}
                    onClick={this.handlePlayPause}
                    disabled={url === null ? true : false}
                  />
                ) : (
                    <Pl.BottomControlsBufferImage src={"/images/Loading.gif"} alt="Loading" disabled />
                  )
              ) : (
                  <Pl.BottomControlsPlayImage
                    src={"/images/player/play.png"}
                    alt="Play"
                    playpause={true}
                    onClick={this.handlePlayPause}
                    disabled={url === null ? true : false}
                  />
                )}
              {this.hasNextTrack() ? (
                <Pl.BottomControlsNextPrevImage
                  src={"/images/player/next.png"}
                  alt="Next Track"
                  onClick={this.handleNextTrack}
                />
              ) : (
                  <Pl.BottomControlsNextPrevImage
                    src={"/images/player/next.png"}
                    alt="Next Track"
                    disabled
                  />
                )}
            </Pl.BottomControlsCenterSection>
            <Pl.BottomControlsRightSection>
              {muted ? (
                <Pl.BottomControlsVolumeImg
                  src={"/images/player/mute.png"}
                  onClick={this.handleToggleMuted}
                />
              ) : (
                  <Pl.BottomControlsVolumeImg
                    src={"/images/player/volume.png"}
                    alt="volumne"
                    onClick={this.handleToggleMuted}
                  />
                )}

              <Pl.BottomControlsRightSectionVolumeSlider
                bgColor="#a2a2a2"
                fgColor="#ffffff"
                type="range"
                min={0}
                max={1}
                step="any"
                value={volume}
                onChange={this.handleVolumeChange}
              />
            </Pl.BottomControlsRightSection>
          </Pl.BottomControlsSubWrapper>
        </Pl.BottomControlsWrapper>
      </FullContainer>
    )
  }
}

export default Player
