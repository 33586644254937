import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"

import {
  PROP_FONT_SECONDARY,
  PROP_FONT_PRIMARY,
} from "../../constants/props"
import { COLOR_THEME_COLOR } from "../../constants/colors"

import styled, { keyframes } from "styled-components"

/*
Components for popup video controls when video is played in maximized mode
*/
export const PopupControlNormalWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  justify-content: space-between;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  transform: ${props =>
    !props.playing ? "translateY(0)" : "translateY(100%) translateY(5px)"};
  transition: all 0.3s;

  @media (max-width: ${MOBILE.maxWidth}px) {
    height: ${MOBILE.player.controls.maximized.height}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.player.controls.maximized.height}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.player.controls.maximized.height}px;
  }
`

export const PopupControlNormalSubWrapper = styled.div`
  display: flex;
  height: 100%;
  color: #272727;
  margin: 0 2vw;
  justify-content: space-between;
  align-items: center;
`

export const PopupControlNormalLeftControls = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  & img {
    margin-right: ${MOBILE.player.controls.maximized.imageMarginRight}px;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & img {
      margin-right: ${LANDSCAPE.player.controls.maximized.imageMarginRight}px;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    & img {
      margin-right: ${TAB.player.controls.maximized.imageMarginRight}px;
    }
  }
`
export const PopupControlNormalRightControls = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  & img {
    margin-left: ${MOBILE.player.controls.maximized.imageMarginLeft}px;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & img {
      margin-left: ${LANDSCAPE.player.controls.maximized.imageMarginLeft}px;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    & img {
      margin-left: ${TAB.player.controls.maximized.imageMarginLeft}px;
    }
  }
`

export const PopupControlNormalImage = styled.img`
  opacity: ${props => (props.disabled ? "0.3" : "1.0")};
  cursor: pointer;
  height: ${MOBILE.player.controls.maximized.imageHeight}vw;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.player.controls.maximized.imageHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.player.controls.maximized.imageHeight}px;
  }
`
export const PopupControlNormalTimeInfo = styled.div`
  display: flex;
  font-family: ${PROP_FONT_SECONDARY};
  font-size: ${MOBILE.player.controls.maximized.imageHeight}vw;
  line-height: ${MOBILE.player.controls.maximized.imageHeight}vw;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.player.controls.maximized.imageHeight}vw;
    line-height: ${LANDSCAPE.player.controls.maximized.imageHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.player.controls.maximized.imageHeight}px;
    line-height: ${TAB.player.controls.maximized.imageHeight}px;
  }
  color: ${COLOR_THEME_COLOR};
`
export const PopupControlNormalVolumeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
`
export const PopupControlNormalVolumeSlider = styled.input`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  position: relative;
  overflow: hidden;
  margin-left: 1vw;
  height: 3.54px;
  background: ${props => props.bgColor};
  outline: none;
  opacity: 0.7;
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  width: ${MOBILE.player.controls.maximized.volumeSliderWidth}vw;
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.player.controls.maximized.volumeSliderWidth}px;
  }

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-runnable-track {
    background: ${props => props.bgColor};
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1px; /* 1 */
    height: 40px;
    background: ${props => props.fgColor};
    box-shadow: -100vw 0 0 100vw ${props => props.fgColor};
    border: none;
  }

  &::-moz-range-track {
    height: 40px;
    background: none;
  }

  &::-moz-range-thumb {
    background: ${props => props.fgColor};
    height: 40px;
    width: 1px;
    border: 0.1px solid ${props => props.fgColor};
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw ${props => props.fgColor};
    box-sizing: border-box;
  }

  &::-ms-fill-lower {
    background: ${props => props.fgColor};
  }

  &::-ms-thumb {
    background: ${props => props.fgColor};
    border: 2px solid ${props => props.fgColor};
    height: 40px;
    width: 1px;
    box-sizing: border-box;
  }

  &::-ms-ticks-after {
    display: none;
  }

  &::-ms-ticks-before {
    display: none;
  }

  &::-ms-track {
    background: none;
    color: transparent;
    height: 40px;
    border: none;
  }

  &::-ms-tooltip {
    display: none;
  }
`

/*
Components for popup video controls when video is played in floating mode
*/

export const PopupControlFloatingWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  background: ${COLOR_THEME_COLOR};
  justify-content: space-between;
  align-items: center;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  transform: ${props =>
    !props.playing ? "translateY(0)" : "translateY(100%) translateY(5px)"};
  transition: all 0.3s;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: ${MOBILE.player.controls.floating.height}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.player.controls.floating.height}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.player.controls.floating.height}px;
  }
  padding: 0 2vw;
  color: #272727;
`

export const PopupControlFloatingSubWrapper = styled.div`
  display: flex;
  height: 100%;
  color: #272727;
  margin: 0 2vw;
  justify-content: space-between;
  align-items: center;
`

export const PopupControlFloatingImage = styled.img`
  opacity: ${props => (props.disabled ? "0.3" : "1.0")};
  cursor: pointer;
  height: ${MOBILE.player.controls.floating.imageHeight}vw;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.player.controls.floating.imageHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.player.controls.floating.imageHeight}px;
  }
`
export const PopupControlFloatingCloseImage = styled.img`
  opacity: ${props => (props.disabled ? "0.3" : "1.0")};
  cursor: pointer;
  height: ${MOBILE.player.controls.floating.closeImageHeight}vw;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.player.controls.floating.closeImageHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.player.controls.floating.closeImageHeight}px;
  }
`

export const PopupControlFloatingLeftControls = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  & img {
    margin-right: ${MOBILE.player.controls.floating.imageMarginRight}px;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & img {
      margin-right: ${LANDSCAPE.player.controls.floating.imageMarginRight}px;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    & img {
      margin-right: ${TAB.player.controls.floating.imageMarginRight}px;
    }
  }
`
export const PopupControlFloatingRightControls = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  & img {
    margin-left: ${MOBILE.player.controls.floating.imageMarginLeft}px;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & img {
      margin-left: ${MOBILE.player.controls.floating.imageMarginLeft}px;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    & img {
      margin-left: ${TAB.player.controls.floating.imageMarginLeft}px;
    }
  }
`

export const PopupControlFloatingTitle = styled.div`
  font-family: ${PROP_FONT_PRIMARY};
  padding: ${props => props.titlePadding}vw 0;
  white-space: nowrap;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: left;
  width: 100%;
  &::selection {
    color: none;
    background: none;
  }
  font-size: ${MOBILE.player.controls.floating.titleFont}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.player.controls.floating.titleFont}px;
  }
`

/*
Components used for player controls at the bottom of screen 
*/

export const BottomControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${MOBILE.player.controls.bottom.height}vw;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.player.controls.bottom.height}px;
    display: ${props => (props.maximized ? "none" : "flex")};
  }
  @media (min-width: ${TAB.minWidth}px) {
    display: flex;
    height: ${TAB.player.controls.bottom.height}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: ${MEDIUM.player.controls.bottom.height}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    height: ${LARGE.player.controls.bottom.height}px;
  }

  background-color: #464a52;
  align-content: center;
  color: #dededf;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
`

export const BottomControlsSubWrapper = styled.div`
  display: flex;
  height: 100%;
  color: #272727;
  width: 100%;
  justify-content: space-between;
`
export const BottomControlsLeftSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  width: 30vw;
`
export const BottomControlsCenterSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    flex: 1;
  }
`
export const BottomControlsRightSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: auto;
  flex: 1;
`
export const BottomControlsInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin-left: 4.17vw;
  @media (max-width: ${MOBILE.maxWidth}px) {
    position: relative;
  }
`
const infiniteScroll = keyframes`
  0%   { left: 100%; }
  100% { left: -100px ; }
`

export const BottomControlsTitle = styled.div`
  font-family: ${PROP_FONT_PRIMARY};
  white-space: nowrap;
  color: #ddddde;
  justify-content: left;
  width: 100%;
  &::selection {
    color: none;
    background: none;
  }
  font-size: ${MOBILE.player.controls.bottom.titleFont}px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    & span {
      position: relative;
      top: 0;
      -moz-animation: ${infiniteScroll} 5s linear infinite;
      -webkit-animation: ${infiniteScroll} 5s linear infinite;
      animation: ${infiniteScroll} 5s linear infinite;
    }
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.player.controls.bottom.titleFont}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.player.controls.bottom.titleFont}px;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }
`

export const BottomControlsSubtitle = styled.p`
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  color: #ddddde;
  text-overflow: ellipsis;
  font-family: ${PROP_FONT_SECONDARY};
  margin: 0;
  &::selection {
    color: none;
    background: none;
  }
  font-size: ${MOBILE.player.controls.bottom.subTitleFont}px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.player.controls.bottom.subTitleFont}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.player.controls.bottom.subTitleFont}px;
    overflow-y: hidden;
  }
`

export const BottomControlsTimeInfo = styled.div`
  display: flex;
  font-family: ${PROP_FONT_SECONDARY};
  font-size: ${LANDSCAPE.player.controls.bottom.timeFont}px;
  line-height: ${LANDSCAPE.player.controls.bottom.timeFont}px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-shrink: 1;
    flex-grow: 0;
    padding: 0px 5px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.player.controls.bottom.timeFont}px;
    line-height: ${LANDSCAPE.player.controls.bottom.timeFont}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.player.controls.bottom.timeFont}px;
    line-height: ${TAB.player.controls.bottom.timeFont}px;
  }
  color: ${COLOR_THEME_COLOR};
`

export const BottomControlsPlayImage = styled.img`
  opacity: ${props => (props.disabled ? "0.3" : "1.0")};
  cursor: pointer;
  height: ${MOBILE.player.controls.bottom.playHeight}vw;
  padding: 0px 10px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.player.controls.bottom.playHeight}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.player.controls.bottom.playHeight}px;
    padding: 0px 20px;
  }
`
export const BottomControlsBufferImage = styled.img`
  opacity: ${props => (props.disabled ? "0.3" : "1.0")};
  cursor: pointer;
  height: ${MOBILE.player.controls.bottom.playHeight}vw;
  padding: 0px 10px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.player.controls.bottom.playHeight}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.player.controls.bottom.playHeight}px;
    padding: 0px 20px;
  }
`

export const BottomControlsNextPrevImage = styled.img`
  opacity: ${props => (props.disabled ? "0.3" : "1.0")};
  cursor: pointer;
  width: auto;
  height: ${MOBILE.player.controls.bottom.nextPrevHeight}vw;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.player.controls.bottom.nextPrevHeight}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.player.controls.bottom.nextPrevHeight}px;
  }
`

export const BottomControlsVolumeImg = styled.img`
  opacity: ${props => (props.disabled ? "0.3" : "1.0")};
  cursor: pointer;
  height: ${MOBILE.player.controls.bottom.volumeHeight}vw;
  vertical-align: inherit;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.player.controls.bottom.volumeHeight}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.player.controls.bottom.volumeHeight}px;
  }
`

export const BottomControlsRightSectionVolumeSlider = styled.input`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  position: relative;
  overflow: hidden;
  margin-left: 1vw;
  margin-right: 4.17vw;
  height: ${MOBILE.player.controls.bottom.volumeSliderHeight}px;
  width: ${MOBILE.player.controls.bottom.volumeSliderWidth}vw;
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.player.controls.bottom.volumeSliderWidth}px;
    height: ${TAB.player.controls.bottom.volumeSliderHeight}px;
  }

  background: ${props => props.bgColor}; /* Grey background */
  outline: none;
  opacity: 0.7;
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-runnable-track {
    background: ${props => props.bgColor};
  }

  /*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1px; /* 1 */
    height: 40px;
    background: ${props => props.fgColor};
    box-shadow: -100vw 0 0 100vw ${props => props.fgColor};
    border: none;
  }

  &::-moz-range-track {
    height: 40px;
    background: none;
  }

  &::-moz-range-thumb {
    background: ${props => props.fgColor};
    height: 40px;
    width: 1px;
    border: 0.1px solid ${props => props.fgColor};
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw ${props => props.fgColor};
    box-sizing: border-box;
  }

  &::-ms-fill-lower {
    background: ${props => props.fgColor};
  }

  &::-ms-thumb {
    background: ${props => props.fgColor};
    border: 2px solid ${props => props.fgColor};
    height: 40px;
    width: 1px;
    box-sizing: border-box;
  }

  &::-ms-ticks-after {
    display: none;
  }

  &::-ms-ticks-before {
    display: none;
  }

  &::-ms-track {
    background: none;
    color: transparent;
    height: 40px;
    border: none;
  }

  &::-ms-tooltip {
    display: none;
  }
`
